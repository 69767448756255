import { Group, Image, Text, useMantineTheme } from '@mantine/core';

export enum SuperProBadgeType {
  SPREAD,
  ICON_LEFT,
}

export type SuperProBadgeProps = {
  displayType?: SuperProBadgeType;
  withShadow?: boolean;
};

export const SuperProBadge = ({
  displayType = SuperProBadgeType.SPREAD,
  withShadow = false,
}: SuperProBadgeProps) => {
  const theme = useMantineTheme();

  return (
    <Group
      maw="fit-content"
      align="center"
      spacing="xxs"
      p="xs"
      style={{
        background: 'white',
        borderRadius: '100px',
        ...(withShadow && { boxShadow: theme.shadows.xs }),
      }}
    >
      {displayType === SuperProBadgeType.ICON_LEFT && (
        <Image
          src="/icons/laurel-wreath.svg"
          alt="laurel icon left"
          style={{
            height: '24px',
            width: '24x',
          }}
        />
      )}
      {displayType === SuperProBadgeType.SPREAD && (
        <Image
          src="/icons/laurel-left.svg"
          alt="laurel icon left"
          style={{
            height: '12px',
            width: '12px',
            marginTop: '-12px',
          }}
        />
      )}
      <Text
        fw="bold"
        style={{
          cursor: 'default',
        }}
      >
        SuperPro
      </Text>

      {displayType === SuperProBadgeType.SPREAD && (
        <Image
          src="/icons/laurel-right.svg"
          alt="laurel icon right"
          style={{
            height: '12px',
            width: '12px',
            marginTop: '-12px',
          }}
        />
      )}
    </Group>
  );
};
