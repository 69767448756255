import {
  Avatar,
  AvatarProps,
  Box,
  Image,
  MantineNumberSize,
  ThemeIcon,
} from '@mantine/core';
import { CompanyMedal } from '@prisma/client';
import { TbUserCircle } from 'react-icons/tb';

import { CottageTooltip } from '@/components/shared/default/CottageTooltip';
import { Role } from '@/lib/constants/role';
import { useUserRoles } from '@/lib/hooks/useUserRoles';
import { getRole, validateRoles } from '@/lib/roleUtils';
import { getInitials } from '@/lib/stringUtils';

const getAvatarColor = (roles: string[]) => {
  const role = getRole(roles);

  let color;

  switch (role) {
    case Role.CUSTOMER:
      color = 'cyan';
      break;
    case Role.CONSULTANT:
      color = 'grape.9';
      break;
    case Role.GC:
      color = 'teal.7';
      break;
    case Role.INTERNAL:
      color = 'gray.6';
      break;
    default:
      color = 'orange';
  }

  return color;
};

export type ProfileAvatarProps = {
  blurPicture?: boolean;
  companyMedal?: CompanyMedal;
  displayNameTooltip?: boolean;
  onClick?: () => void;
  profilePictureName?: string | null;
  roles?: string[];
  userName?: string | null;
} & AvatarProps;

export default function ProfileAvatar({
  blurPicture,
  companyMedal,
  displayNameTooltip,
  onClick,
  profilePictureName,
  roles = [],
  size = 'md',
  userName,
  ...otherProps
}: ProfileAvatarProps) {
  const userRoles = useUserRoles();
  const { isCustomer, isInternal } = validateRoles(userRoles);
  const isViewerCustomerOrInternal = isCustomer || isInternal;
  let avatar;

  if (profilePictureName) {
    avatar = (
      <Avatar
        size={size}
        onClick={onClick}
        radius="xl"
        src={profilePictureName}
        alt="Profile image"
        sx={{
          img: {
            filter: blurPicture ? 'blur(5px)' : 'none',
            userSelect: 'none', // Prevent selecting and copying the image easily
            pointerEvents: 'none', // Prevent right-click actions like "Save image as..."
          },
        }}
        {...otherProps}
      />
    );
  } else if (userName) {
    const nameInitials = getInitials(userName, isViewerCustomerOrInternal);
    avatar = (
      <Avatar
        size={size}
        radius="xl"
        variant="filled"
        onClick={onClick}
        color={getAvatarColor(roles)}
        alt={nameInitials}
        {...otherProps}
      >
        {nameInitials}
      </Avatar>
    );
  } else {
    // Mantine's default placeholder avatar
    avatar = (
      <Avatar
        onClick={onClick}
        size={size}
        radius="xl"
        alt="generic user icon"
        {...otherProps}
      >
        <TbUserCircle />
      </Avatar>
    );
  }

  avatar = maybeAddMedal({ avatar, companyMedal, size });

  return displayNameTooltip && !!userName ? (
    <CottageTooltip label={userName}>{avatar}</CottageTooltip>
  ) : (
    avatar
  );
}

type MaybeAddMedalParams = {
  avatar: JSX.Element;
  size: MantineNumberSize;
  companyMedal?: CompanyMedal;
};

const maybeAddMedal = ({ avatar, companyMedal, size }: MaybeAddMedalParams) => {
  switch (companyMedal) {
    case CompanyMedal.SUPERPRO:
      return (
        <Box pos="relative">
          {avatar}
          <ThemeIcon
            pos="absolute"
            right={-6}
            top={-6}
            radius="xl"
            size={size}
            variant="laurel"
          >
            <Image
              alt="laurel"
              src="/icons/laurel-wreath.svg"
              style={{
                height: '80%',
                width: '80%',
              }}
            />
          </ThemeIcon>
        </Box>
      );
    default:
      return avatar;
  }
};
