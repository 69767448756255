import { Box, createStyles, Flex, Group, Text } from '@mantine/core';
import {
  Dropzone as MantineDropzone,
  FileRejection,
  FileWithPath,
} from '@mantine/dropzone';
import { forwardRef, useState } from 'react';
import { TbUpload, TbX } from 'react-icons/tb';

import { Document } from '@/types/api/documents';

const useStyles = createStyles((theme) => ({
  error: {
    borderColor: 'red',
  },
  iconCircle: {
    width: '36px',
    height: '36px',
    backgroundColor: theme.colors.blue[0],
    borderRadius: '50%',
  },
}));

export type DropzoneProps = {
  acceptableMimeTypes: string[];
  acceptableTypesAsString: string;
  filesToUpload: FileWithPath[];
  setFilesToUpload: (files: FileWithPath[]) => void;
  clearErrors?: () => void;
  errorMsg?: string;
  existingFiles?: Document[];
  isSingleFile?: boolean;
  maxSize?: number;
  modal?: boolean;
  setExistingFiles?: (documents: Document[]) => void;
  showPreviewTile?: boolean;
};

/**
 * Given a list of existing files and a list of new files, this function returns
 * an array of files that exist in both lists.
 *
 * @param existingFiles
 * @param newFiles
 * @returns an array of duplicate files
 */
function findDuplicateFiles(
  existingFileNameSet: Set<string>,
  newFiles: FileWithPath[]
) {
  return newFiles.filter((f) => existingFileNameSet.has(f.name));
}

/**
 * Given a list of duplicate files, this function generates an appropriate error
 * message
 * @param duplicates
 * @returns an error message
 */
function getDuplicateFilesErrorMessage(duplicates: FileWithPath[]) {
  if (!duplicates.length) {
    return '';
  }
  if (duplicates.length === 1) {
    return `Cannot upload a file that already exists: "${duplicates[0].name}"`;
  }

  return `Cannot upload files that already exist: ${duplicates.map((d) => `"${d.name}"`).join(', ')}`;
}

export const Dropzone = forwardRef<() => void, DropzoneProps>(
  (
    {
      acceptableMimeTypes,
      acceptableTypesAsString,
      filesToUpload,
      setFilesToUpload,
      existingFiles,
      setExistingFiles,
      errorMsg,
      isSingleFile,
      maxSize,
      clearErrors,
      modal = false,
    }: DropzoneProps,
    ref
  ) => {
    const [fileRejectionErrors, setFileRejectionErrors] = useState<string[]>(
      []
    );

    const { classes } = useStyles();

    const handleDrop = (
      newFiles: FileWithPath[],
      fileRejections: FileRejection[],
      existingFiles: Document[]
    ) => {
      setFileRejectionErrors([]);
      if (clearErrors) clearErrors();
      const allFiles = new Set([
        ...existingFiles.map((f) => f.name),
        ...filesToUpload.map((f) => f.name),
      ]);
      const duplicateFiles = findDuplicateFiles(allFiles, newFiles);
      const duplicateFileErrors: string[] = [];

      if (duplicateFiles.length > 0) {
        duplicateFileErrors.push(getDuplicateFilesErrorMessage(duplicateFiles));
      }

      const fileRejectionsErrors = fileRejections.flatMap((file) =>
        file.errors.map((error) => {
          // known error codes from Mantine Dropzone component
          if (error.code === 'file-too-large') {
            return `File "${file.file.name}" exceeds 50MB`;
          } else if (error.code === 'file-invalid-type') {
            return `Invalid file format. File must be ${acceptableTypesAsString}`;
          }

          return error.message;
        })
      );
      const errors = [...fileRejectionsErrors, ...duplicateFileErrors];
      setFileRejectionErrors(errors);

      if (errors.length > 0) {
        return;
      }

      // If there are no errors, set the files to upload.
      if (isSingleFile) {
        // Only allow the user to drop one file at a time
        const newFile = newFiles[0];
        setFilesToUpload([newFile]);
        setExistingFiles?.([]);
      } else {
        setFilesToUpload([...filesToUpload, ...newFiles]);
      }
    };

    const errors = errorMsg
      ? fileRejectionErrors.concat(errorMsg)
      : fileRejectionErrors;

    const rejectionMessages = errors.length ? (
      <Box>
        {errors.map((error, i) => (
          <Text key={`${error}-${i}`} size="xs" color="red">
            {error}
          </Text>
        ))}
      </Box>
    ) : (
      <></>
    );

    return (
      <>
        <MantineDropzone
          className={errorMsg ? classes.error : ''}
          openRef={ref}
          onDrop={() => {}}
          onDropAny={(files, fileRejections) =>
            handleDrop(files, fileRejections, existingFiles ?? [])
          }
          radius="md"
          accept={acceptableMimeTypes}
          maxSize={maxSize ? maxSize : 50 * 1024 ** 2} // 50mb
          padding="1.5rem"
          data-testid="document-drop"
        >
          <Flex
            align="center"
            direction="column"
            justify="center"
            style={{ pointerEvents: 'none' }}
          >
            <Group className={classes.iconCircle} position="center">
              <MantineDropzone.Accept>
                <TbUpload size={26} />
              </MantineDropzone.Accept>
              <MantineDropzone.Reject>
                <TbX size={26} color="red.6" />
              </MantineDropzone.Reject>
              <MantineDropzone.Idle>
                <TbUpload size={26} />
              </MantineDropzone.Idle>
            </Group>

            <Text ta="center" variant={modal ? 'headerMd' : 'headerLg'}>
              <MantineDropzone.Accept>Drop files here</MantineDropzone.Accept>
              <MantineDropzone.Reject>
                Invalid file format
              </MantineDropzone.Reject>
              <MantineDropzone.Idle>
                Click to upload or drag and drop
              </MantineDropzone.Idle>
            </Text>
            <Text ta="center" fz={modal ? 'xs' : 'sm'} mt="xs" c="dimmed">
              {acceptableTypesAsString}
            </Text>
          </Flex>
        </MantineDropzone>
        {rejectionMessages}
      </>
    );
  }
);

Dropzone.displayName = 'CottageDropzone';
