import { DocumentCategory } from '@prisma/client';

export const DOCUMENT_CATEGORY_TEXT: {
  [key: string]: string;
} = {
  CONCEPTUAL_DESIGN: 'Conceptual Design',
  SCHEMATIC_DESIGN: 'Schematic Design',
  DESIGN_DEVELOPMENT: 'Design Development',
  SPECS_AND_FINISHES: 'Specs and Finishes',
  PERMITTING: 'Permitting',
  CONSTRUCTION: 'Construction',
  CONSULTANT_DELIVERABLE: 'Consultant Deliverable',
  PROCUREMENT: 'Procurement',
  PROPOSAL_AND_CONTRACTS: 'Proposal and Contract',
} as const;

export const DOCUMENT_CATEGORY_BADGE_VARIANT: {
  [key: string]: string;
} = {
  CONCEPTUAL_DESIGN: 'blue',
  SCHEMATIC_DESIGN: 'blue',
  DESIGN_DEVELOPMENT: 'blue',
  SPECS_AND_FINISHES: 'blue',
  PERMITTING: 'olive',
  CONSTRUCTION: 'brown',
  CONSULTANT_DELIVERABLE: 'gray',
  PROCUREMENT: 'blueDark',
  PROPOSAL_AND_CONTRACTS: 'gray',
} as const;

export const CATEGORY_DESCRIPTIONS: Partial<Record<DocumentCategory, string>> =
  {
    DESIGN_DEVELOPMENT:
      'For permit documentations, please upload it with the Permitting type.',
    PERMITTING:
      'Documents related to permit approval for the project, including permit ' +
      'documentations, applications, inspection reports, etc. For final ' +
      'approved plans, please upload it with the Construction type.',
  };
