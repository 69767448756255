import { Identify, identify } from '@amplitude/analytics-browser';

import prisma from '@/lib/prisma';
import { Company, Project } from '@/types/api';

import {
  AllowedProRequesterRole,
  COMPANY_ROLES,
  REQUESTER_ROLE_TO_ALLOWED_REQUESTED_ROLES,
  Role,
  ROLE_TO_CREATED_BY,
} from './constants/role';

interface sessionUser {
  roles: string[];
  userId: string;
  email?: string | null | undefined;
  image?: string | null | undefined;
  name?: string | null | undefined;
}

export const validateIsArchitect = (roles: string[]) => {
  return roles.includes(Role.ARCHITECT);
};

export const validateIsCompanyOwner = (roles: string[]) => {
  const isCompanyOwner =
    roles.includes(Role.COMPANY_OWNER) ||
    // TODO: Remove after all sessions have been renewed.
    roles.includes('consultant_owner') ||
    roles.includes('gc_owner');

  return isCompanyOwner;
};

export const validateIsProjectOwner = async (
  roles: string[],
  userId?: number
) => {
  let isProjectOwner = roles.includes(Role.PROJECT_OWNER);
  // Only check against db if the user is a customer
  // TODO: Remove after all sessions have been renewed.
  if (!isProjectOwner && userId && validateIsCustomer(roles)) {
    const projectCustomer = await prisma.project_customers.findFirst({
      where: {
        customer: userId,
      },
    });

    if (projectCustomer?.status === 'owner') {
      isProjectOwner = true;
    }
  }

  return isProjectOwner;
};

export const validateIsConsultant = (roles: string[]) => {
  return roles.includes(Role.CONSULTANT);
};

export const validateIsInternal = (roles: string[]) => {
  return roles.includes(Role.INTERNAL);
};

export const validateIsGc = (roles: string[]) => {
  return roles.includes(Role.GC);
};

export const validateIsSupplier = (roles: string[]) => {
  return roles.includes(Role.SUPPLIER);
};

export const validateIsCustomer = (roles: string[]) => {
  // TODO: Remove check for HOMEOWNER role after all sessions have been renewed.
  // Remember to also remove the role from roles.ts file.
  return roles.includes(Role.CUSTOMER) || roles.includes(Role.HOMEOWNER);
};

export const validateIsProfessional = (roles: string[]) => {
  return (
    validateIsArchitect(roles) ||
    validateIsConsultant(roles) ||
    validateIsGc(roles) ||
    validateIsSupplier(roles)
  );
};

export const validateIsExternal = (roles: string[]) => {
  return validateIsProfessional(roles) || validateIsCustomer(roles);
};

export const validateIsProfessionalOrInternal = (roles: string[]) => {
  return validateIsProfessional(roles) || validateIsInternal(roles);
};

export const validateRoles = (
  roles: string[]
): { [index: string]: boolean } => {
  const roleResults = {
    isArchitect: false,
    isCompanyOwner: false,
    isConsultant: false,
    isCustomer: false,
    isGc: false,
    isInternal: false,
    isSupplier: false,
  };

  for (const role of roles) {
    switch (role) {
      case Role.ARCHITECT:
        roleResults.isArchitect = true;
        break;
      case Role.COMPANY_OWNER:
      case 'consultant_owner': // TODO: Remove after all sessions have been renewed.
      case 'gc_owner': // TODO: Remove after all sessions have been renewed.
        roleResults.isCompanyOwner = true;
        break;
      case Role.CONSULTANT:
        roleResults.isConsultant = true;
        break;
      case Role.CUSTOMER:
      case Role.HOMEOWNER:
        roleResults.isCustomer = true;
        break;
      case Role.GC:
        roleResults.isGc = true;
        break;
      case Role.INTERNAL:
        roleResults.isInternal = true;
        break;
      case Role.SUPPLIER:
        roleResults.isSupplier = true;
        break;
    }
  }

  return roleResults;
};

/**
 * Validates that the user has any of the supported roles passed in.
 *
 * @param roles The roles of the current logged in user
 * @param supportedRoles The roles to check the user is in
 * @returns true if the user has any of the supported roles.
 */
export const validateIsAnyRole = (
  userRoles: string[],
  supportedRoles: Role[]
) => supportedRoles.includes(getRole(userRoles));

/**
 * @param role The role string to check
 * @returns Whether the role is a valid Role, excluding Role.NONE
 */
export const isRole = (role: string): role is Role => {
  return (
    Object.values(Role).includes(role as Role) && role !== Role.NONE.valueOf()
  );
};

/**
 * @param roles The array of strings of roles to check
 * @returns Whether all the roles are a valid Role, excluding Role.NONE
 */
export const areAllRoles = (roles: string[]): roles is Role[] => {
  return roles.every((r) => isRole(r));
};

/**
 * @param roles The array of strings of roles to check
 * @returns Whether at least one of the roles is a valid Role, excluding
 * Role.NONE
 */
export const isAnyRole = (roles: string[]): boolean => {
  return roles.some((r) => isRole(r));
};

export const getDbRoleToRole = (dbRole: string) => {
  switch (dbRole) {
    case 'architect':
      return Role.ARCHITECT;
    case 'consultant':
      return Role.CONSULTANT;
    case 'customer':
      return Role.CUSTOMER;
    case 'gc':
      return Role.GC;
    case 'homeowner':
      return Role.HOMEOWNER;
    case 'internal':
      return Role.INTERNAL;
    case 'supplier':
      return Role.SUPPLIER;
    default:
      return Role.NONE;
  }
};

/**
 * Returns the Role of the user, this function assumes each user only has one
 * role for now. Does not include company owner.
 * TODO: This is at best a utility function for the useCompany hook and should
 * not be used in new hooks, as we should ultimately refactor and remove the
 * need to pass roles into hooks.
 * @param roles The array of strings of roles to check
 * @returns The Role of the user
 */
export const getRole = (roles: string[]): Role => {
  if (validateIsArchitect(roles)) {
    return Role.ARCHITECT;
  } else if (validateIsConsultant(roles)) {
    return Role.CONSULTANT;
  } else if (validateIsCustomer(roles)) {
    return Role.CUSTOMER;
  } else if (validateIsGc(roles)) {
    return Role.GC;
  } else if (validateIsInternal(roles)) {
    return Role.INTERNAL;
  } else if (validateIsSupplier(roles)) {
    return Role.SUPPLIER;
  }

  return Role.NONE;
};

/**
 * Returns the subrole of the user, this function is utilized by login to track
 * the subrole of the user. Other than company owner and project owner, the
 * other subroles returned by this function are not useful in code.
 * @param roles The array of strings of roles to check
 * @returns The subrole of the user
 */
export const getSubRole = async (
  roles: string[]
): Promise<Role | string | null> => {
  if (validateIsCompanyOwner(roles)) {
    return Role.COMPANY_OWNER;
  } else if (await validateIsProjectOwner(roles)) {
    return Role.PROJECT_OWNER;
  } else if (validateIsInternal(roles)) {
    return 'admin';
  } else if (validateIsProfessional(roles)) {
    return 'partner';
  }

  return null;
};

/**
 * Temporary client-side identify function to identify users from the homepage
 * since they have not logged in for a while. TODO: remove after all none users
 * have been identified in Amplitude.
 * @param role The role of the user
 * @param user The session user object
 * @param company The company the user is associated with, if Pro
 * @param project The project the user is associated with, if Customer
 */
export const identifyAmplitude = (
  role: Role,
  user: sessionUser | undefined,
  company?: Company | undefined,
  project?: Project | undefined
) => {
  if (user) {
    const identifyObj = new Identify();
    const subRole = validateIsCompanyOwner(user.roles ?? [])
      ? 'company_owner'
      : 'partner';
    identifyObj.setOnce('userEmail', user.email!);
    identifyObj.set('userId', user.userId);
    identifyObj.set('role', role);
    if (subRole) {
      identifyObj.set('subRole', subRole);
    }
    if (company?.name) {
      identifyObj.set('companyName', company.name);
    }
    if (project?.name) {
      identifyObj.set('projectName', project.name);
    }
    identify(identifyObj, {
      user_id: user.email!,
    });
  }
};

export const canUserRequestPro = (requesterRole: Role, requestedRole: Role) => {
  return !!REQUESTER_ROLE_TO_ALLOWED_REQUESTED_ROLES[
    requesterRole as AllowedProRequesterRole
  ]?.includes(requestedRole);
};

export const isProUser = (userRole: Role | string | (Role | string)[]) => {
  if (Array.isArray(userRole)) {
    return userRole.some((role) => COMPANY_ROLES.has(role as Role));
  }

  return COMPANY_ROLES.has(userRole as Role);
};

export const getCreatedBy = (role: Role) => {
  return ROLE_TO_CREATED_BY[role];
};

export const getCustomerDisplayTitle = (viewerRole: Role) => {
  if (viewerRole === Role.CUSTOMER) {
    return 'you';
  }

  return 'the homeowner';
};
