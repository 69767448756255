import { Avatar, createStyles, Flex, Group, Paper, Text } from '@mantine/core';
import { TbChevronRight } from 'react-icons/tb';

const useStyles = createStyles((theme) => ({
  link: {
    '&:hover': {
      background: theme.colors.white[1],
    },
  },
  action: {
    color: theme.colors.blue[7],
    fontWeight: 700,
  },
}));

type CottageLinkProps = {
  href: string | undefined;
  name: string;
  icon?: JSX.Element;
  iconBackgroundColor?: string;
  onClick?: () => void;
  variant?: string;
};

/**
 * Creates a clickable paper that opens a link to in another tab
 * @returns CottageLink
 */
export const CottageLink = ({
  href,
  name,
  icon,
  iconBackgroundColor,
  onClick,
  variant = 'light',
}: CottageLinkProps) => {
  const { classes } = useStyles();

  return (
    <>
      <a href={href} target="_blank" onClick={onClick}>
        <Paper p="xs" withBorder className={classes.link}>
          <Flex align="center" justify="space-between">
            <Group align="center">
              {icon && (
                <Avatar size={48} variant={variant} color={iconBackgroundColor}>
                  {icon}
                </Avatar>
              )}
              <Text fw="bold">{name}</Text>
            </Group>
            <TbChevronRight size="1rem" />
          </Flex>
        </Paper>
      </a>
    </>
  );
};
