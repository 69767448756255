import { useFlags } from 'launchdarkly-react-client-sdk';

type GcProposalLegacyMarketplaceProjects = {
  projectIds: number[];
};

type CottageFeatureFlags = {
  associateNewInvoiceWithWorkOrder: boolean;
  designerHomeownerWorkOrder: boolean;
  docStorage: boolean;
  doubleOptIn: boolean;
  downtimeMessage: string;
  feedback: string;
  gcProcurements: boolean;
  gcProcurementsMigration: boolean;
  gcProposalParity: boolean;
  homeownerDesignerGcRfp: boolean;
  instantMatch: boolean;
  kickoffCloseout: boolean;
  projectTrackerTeamRoster: boolean;
  purchaseOrders: boolean;
  upsellBanner: boolean;
  gcProposalLegacyMarketplaceProjects?: GcProposalLegacyMarketplaceProjects;
};

export const useCottageFlags = useFlags<CottageFeatureFlags>;
