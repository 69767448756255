import useSWRImmutable from 'swr/immutable';

import { SupportCategories } from '@/types/api';

import { createGetFetcher } from '../api/client/fetchers';

const get = createGetFetcher('support categories');

export const useSupportCategories = (shouldCall = true) => {
  const { data, isLoading, error } = useSWRImmutable<SupportCategories, Error>(
    shouldCall && '/api/support/categories',
    get
  );

  return {
    supportCategories: data,
    loading: isLoading,
    error,
  };
};
