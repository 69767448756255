import * as amplitude from '@amplitude/analytics-browser';
import { createStyles, Flex, Group, Image } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { useContext, useEffect, useState } from 'react';
import { TbMenu2, TbX } from 'react-icons/tb';

import { ANALYTICS_EVENTS } from '@/lib/constants/analyticsEvents';
import { getRole } from '@/lib/roleUtils';
import { zIndex } from '@/lib/styleUtils';

import ActionBar, { ActionBarPosition } from '../shared/ActionBar';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { DisplaySize, SizeContext } from '../shared/contexts/SizeContext';
import { SupportModal } from '../shared/SupportModal';
import Menu from './Menu';
import { SupportButton } from './SupportButton';
import UserMenu, { UserMenuCompactDisplayStrategy } from './UserMenu';

export const NAVIGATION_HEIGHT = 72;

const useStyles = createStyles((theme) => ({
  container: {
    padding: '0 5%',
    color: 'white',
    // must explicitly set height here for links to fully span nav bar height
    height: NAVIGATION_HEIGHT,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      justifyContent: 'space-between',
    },
    '@media print': {
      display: 'none',
    },
  },
  cottageLogo: {
    cursor: 'pointer',
  },
  mobileMenuButton: {
    cursor: 'pointer',
  },
}));

export default function Navigation({ session }: { session: Session | null }) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);

  const [
    supportModalOpen,
    { open: openSupportModal, close: closeSupportModal },
  ] = useDisclosure(false);

  const router = useRouter();
  const { classes } = useStyles();

  const { layout } = useContext(LayoutContext);

  const isMobile = layout === LayoutType.MOBILE;
  const isTablet = layout === LayoutType.TABLET;

  useEffect(() => {
    if (!isMobile) {
      setIsMobileNavOpen(false);
    }
  }, [isMobile]);

  const role = getRole(session?.user?.roles ?? []);

  const handleClickSupportButton = () => {
    amplitude.track({
      event_type: ANALYTICS_EVENTS.SUPPORT_BUTTON_CLICKED,
    });
    openSupportModal();
  };

  return (
    <SizeContext.Provider
      value={{ size: isMobile ? DisplaySize.COMPACT : DisplaySize.DEFAULT }}
    >
      <ActionBar
        position={ActionBarPosition.TOP}
        withShadow={false}
        padding="0"
        zIndex={zIndex.NAVIGATION}
      >
        <Flex
          className={classes.container}
          direction="row"
          align="center"
          bg="blue.9"
          gap={isTablet ? 'sm' : 'xl'}
        >
          <Image
            alt="Cottage Logo"
            src="/images/cottage-logo.png"
            width={40}
            height={40}
            className={classes.cottageLogo}
            onClick={() => {
              void router.push('/');
            }}
          />
          {session && !router.asPath.includes('/auth/signin') ? (
            <>
              {(!isMobile || isMobileNavOpen) && (
                <Menu
                  role={role}
                  userEmail={session.user.email ?? ''}
                  userName={session.user.name ?? ''}
                  setIsMobileNavOpen={setIsMobileNavOpen}
                />
              )}

              {/* Right Navigation */}
              {isMobile ? (
                <Group>
                  <SupportButton onClick={handleClickSupportButton} size="sm" />
                  {isMobileNavOpen ? (
                    <TbX
                      className={classes.mobileMenuButton}
                      onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                      size={24}
                    />
                  ) : (
                    <TbMenu2
                      className={classes.mobileMenuButton}
                      onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                      size={24}
                    />
                  )}
                </Group>
              ) : (
                <Group ml="auto" spacing={isTablet ? 'lg' : 'xl'} noWrap>
                  <SupportButton
                    onClick={handleClickSupportButton}
                    size={isTablet ? 'sm' : 'lg'}
                  />
                  <UserMenu
                    userName={session.user.name ?? ''}
                    userEmail={session.user.email ?? ''}
                    displayStrategy={UserMenuCompactDisplayStrategy.ICON_ONLY}
                  />
                </Group>
              )}

              <SupportModal
                opened={supportModalOpen}
                onClose={() => {
                  closeSupportModal();
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Flex>
      </ActionBar>
    </SizeContext.Provider>
  );
}
