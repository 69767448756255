import { ActionIcon, Avatar, Card, Flex, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import {
  TbFileText,
  TbJpg,
  TbNote,
  TbPdf,
  TbPng,
  TbX,
  TbZip,
} from 'react-icons/tb';

import { FileExtension, getFileExtension } from '@/lib/documentUtils';

type DocumentUploadTileProps = {
  fileName: string;
  fileType: string;
  onDelete: (fileName: string) => void;
};

function DocumentUploadTile({
  fileName,
  fileType,
  onDelete: handleDelete,
}: DocumentUploadTileProps) {
  let previewIcon;
  switch (getFileExtension(null, fileType)) {
    case FileExtension.DOC:
    case FileExtension.DOCX:
      previewIcon = <TbFileText />;
      break;
    case FileExtension.PDF:
      previewIcon = <TbPdf />;
      break;
    case FileExtension.ZIP:
      previewIcon = <TbZip />;
      break;
    case FileExtension.PNG:
      previewIcon = <TbPng />;
      break;
    case FileExtension.JPEG:
      previewIcon = <TbJpg />;
      break;
    case FileExtension.NONE:
    default:
      previewIcon = <TbNote />;
  }

  return (
    <Card
      withBorder
      pt={0}
      pl={0}
      pr={0}
      w={150}
      style={{
        overflow: 'hidden',
      }}
    >
      <Flex direction="row-reverse">
        <IconContext.Provider value={{ size: '24px' }}>
          <ActionIcon
            variant="transparent"
            onClick={() => handleDelete(fileName)}
            aria-label="delete"
          >
            <TbX />
          </ActionIcon>
        </IconContext.Provider>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap="md"
        h="100%"
      >
        <Avatar size={48}>
          <IconContext.Provider value={{ size: '32px' }}>
            {previewIcon}
          </IconContext.Provider>
        </Avatar>
        <Text
          size="xs"
          style={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {fileName}
        </Text>
      </Flex>
    </Card>
  );
}

export default DocumentUploadTile;
