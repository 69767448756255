import {
  AgreementServiceType,
  AgreementType,
  CategoryType,
  CompanyMedal,
  CompanyType,
  ConstructionKickoffStatus,
  ConsultantType,
  HighlightTag,
  InvoiceType,
  PlatformServiceType,
  Prisma,
  PrismaClient,
  ProcurementCategory as PrismaProcurementCategory,
  ProcurementCategory,
  ProjectPhase,
  ProjectStage,
  Region,
  StaticAttribute,
  UserStatus,
} from '@prisma/client';
import { EnvelopeDefinition } from 'docusign-esign';
import Stripe from 'stripe';

import { PaymentMethodType } from '@/lib/constants/paymentMethods';
import { Role } from '@/lib/constants/role';
import {
  Document,
  DocumentSelect,
  DownloadDocuments,
} from '@/types/api/documents';

import { Proposal } from './api/proposals';

export type HttpError = {
  message: string;
};

export type ProjectCustomer = {
  customers: {
    id: number;
    transactional_sms_enabled: boolean;
    email: string | null;
    full_name: string | null;
    phone_number: string | null;
    status?: string | null;
  } | null;
};

export type GcCompany = {
  city: string | null;
  email: string | null;
  id: number;
  name: string;
  profile_picture: string | null;
  state: string | null;
  static_attributes: HighlightTag[];
};

export type PhaseStage = Prisma.phase_and_stageGetPayload<null>;

export type Project = {
  id: string;
  phasesAndStages: PhaseStage[];
  platformServiceType: PlatformServiceType;
  uuid: string;
  airtableId?: string | null;
  architectDocFolderUrl?: string | null;
  bathrooms?: number | null;
  bedrooms?: number | null;
  companies?: { company_type: CompanyType; id: number; name: string }[] | null;
  consultantDocFolderUrl?: string | null;
  createdAt?: string;
  currentPhase?: ProjectPhase | null;
  currentStage?: ProjectStage | null;
  customerCsatSubmitted?: boolean;
  customerOwnerEmail?: string | null;
  customerOwnerId?: string | null;
  customerOwnerName?: string | null;
  customerOwnerPhoneNumber?: string | null;
  designPacketLink?: string | null;
  designer?: string | null;
  electricalUpgrade?: string | null;
  finishesSchedulesLink?: string | null;
  foundationType?: string | null;
  gcCsatSubmitted?: boolean;
  gcDocFolderUrl?: string | null;
  gdriveSiteSketchLink?: string | null;
  hasPositiveCustomerCsat?: boolean;
  interiorFinishesTier?: string | null;
  interiorStyle?: string | null;
  isMultibid?: boolean;
  municipality?: string | null;
  name?: string | null;
  //deprecated
  oldPhase?: string | null;
  oldStage?: string | null;
  oldStages?: OldProjectStage[];
  phase2SigningDate?: string | null;
  //deprecated
  phaseOrder?: number;
  projectCustomers?: ProjectCustomer[];
  //deprecated
  projectStagesAndStatusRecordId?: string | null;
  projectSubstatus?: string | null;
  projectType?: string | null;
  projectTypeFromProjectTypes?: string[] | null;
  proposals?: Proposal[];
  retainingWallSqft?: number | null;
  salesManagerName?: string | null;
  soilsStatus?: string | null;
  sqft?: number | null;
  //deprecated
  stageOrder?: number;
  status?: string | null;
  //deprecated
  statusOrder?: number;
  targetBudget?: number | null;
  tsvLink?: string | null;
  woodenDeckSqft?: number | null;
};

export type OldProjectStage = {
  name: string;
  status: string;
  endDate?: string;
  startDate?: string;
};

export type ProjectLog = {
  name: string;
  startDate: string;
  endDate?: string;
};

export enum InvoiceRole {
  ISSUER,
  PAYER,
}

export type Invoice = {
  details: string | null;
  dueAt: Date;
  id: string;
  invoiceId: string;
  issuedAt: Date;
  name: string | null;
  paidAt: Date | null;
  processingStartedAt: Date | null;
  projectId: string;
  projectUuid: string;
  status: string;
  totalDueCents: number;
  uuid: string;
  allowedPaymentMethodTypes?: string[];
  changeOrderUuid?: string | null;
  changeOrderVersion?: number | null;
  customerEmail?: string | null;
  customerId?: string | null;
  customerName?: string | null;
  customerPhoneNumber?: string | null;
  documents?: Document[];
  invoiceType?: InvoiceType | null;
  issuerCompanyInfo?: ProfessionalCompanyInvoiceData;
  marketplaceFeeCents?: number | null;
  payerCompanyInfo?: ProfessionalCompanyInvoiceData;
  paymentMethod?: string;
  processingFee?: number | null;
  projectName?: string;
  projectZipCode?: string | null;
  purchaseOrderUuid?: string | null;
  stripeChargeId?: string;
  stripeInvoiceId?: string | null;
  voidedReason?: string;
  workOrderUuid?: string | null;
};

export type ProfessionalCompanyInvoiceData = {
  companyAddress?: string | null;
  companyCity?: string | null;
  companyEmail?: string;
  companyId?: string | null;
  companyName?: string;
  companyState?: string | null;
  companyType?: CompanyType;
  companyZipCode?: string | null;
};

export type CreateInvoiceRequest = {
  details: string;
  documents: Document[];
  dueAt: string;
  invoiceReadableId: string;
  name: string;
  projectName: string;
  projectUuid: string;
  totalDueCents: number;
  changeOrderUuid?: string;
  changeOrderVersion?: number;
  constructionPhaseInfo?: ChangelogUpdateRequest;
  currentPhase?: ProjectPhase;
  currentStage?: ProjectStage;
  customerId?: string;
  isPassthroughExpense?: boolean;
  purchaseOrderUuid?: string;
  workOrderUuid?: string;
};

export type GetInvoicesResponse = {
  issuerInvoices: Invoice[];
  payerInvoices: Invoice[];
};

export type ProjectState = {
  phaseOrder: number;
  stageOrder: number;
  statusOrder: number;
};

export type ChangelogUpdateRequest = ProjectState & {
  effectiveDate?: string;
  endDate?: string;
  oldState?: ProjectState;
};

export type CreateMilestoneInvoiceRequest = {
  airtableId: string;
  details: string;
  invoiceName: string;
  invoiceType: InvoiceType;
  totalDueCents: number;
};

export type CreateMilestoneInvoiceResponse = {
  uuid: string;
};

export type Customer = {
  email: string | null;
  fullName: string | null;
  id: string;
  phoneNumber: string | null;
  stripeAccounts?: UserStripeAccount[];
};

export type UserStripeAccount = {
  customerId: string;
  paymentMethods?: Stripe.PaymentMethod[];
};

export type AddStripeBankAccountRequest = {
  bankAccountToken: string;
};

export type ResetPasswordRequest = {
  password: string;
  token: string;
  userId: string;
  userRole: string;
};

export type ForgotPasswordParams = {
  id: number;
  role: Role;
  token: string;
};

export type AddStripeBankAccountResponse = {
  bankLast4: string;
  bankName: string;
};

export type ExchangeLinkTokenRequest = {
  accountId: string;
  publicToken: string;
};

export type ExchangeLinkTokenResponse = {
  token: string;
};

export type User = {
  email: string;
  firstName: string;
  isOwner: boolean;
  lastName: string;
  phoneNumber: string | null;
};

export type Testimonial = {
  reviewer_location: string;
  reviewer_name: string;
  text: string;
};

export type BaseCompany = {
  about_info: string | null;
  city: string | null;
  company_type: CompanyType;
  email: string;
  id: number;
  name: string;
  profile_picture: string | null;
  state: string | null;
  users: User[];
  badges?: HighlightTag[];
  company_derived_data_cache?: {
    badges?: HighlightTag[];
    experienced_municipalities?: string[];
    projects_completed?: number;
  };
  company_testimonial?: Testimonial[];
  medal?: CompanyMedal;
  projectsCompleted?: number;
  static_attributes?: StaticAttribute[];
  superproBadge?: CompanyMedal;
  website?: string;
};

type BaseCompanyWithImages = BaseCompany & {
  media?: Media[];
};

export type Company = BaseCompanyWithImages & CompanyStripeDetails;

export type BasePgCompany = Prisma.companyGetPayload<null>;

export type CompanyStripeDetails = {
  hasPayoutsEnabled?: boolean;
  stripeAccountId?: string | null;
};

export type StripeDashboardLink = {
  url: string;
};

export type PayInvoiceResponse = {
  intent: Stripe.Response<Stripe.PaymentIntent>;
  invoice: Invoice;
};

export type SuccessConfirmation = {
  success: boolean;
};

export type StripeDashboardLinkRequest = {
  company: Company;
  redirectUrl: string;
  refreshUrl: string;
};

export type PayInvoiceRequest = {
  invoiceUuid: string;
  paymentMethodId: string;
  paymentMethodType: string;
};

export type PayInvoiceByCheckRequest = {
  invoiceUuid: string;
  projectUuid: string;
};

export type GetStripeInvoicePdfRequest = {
  invoiceUuid: string;
};

export type GetStripeInvoicePdfResponse = {
  invoicePdf: string;
};

export type SendForgotPasswordEmailRequest = {
  userEmail: string;
};

export enum WorkOrderClientType {
  CUSTOMER = 'customer',
  PRO = 'pro',
}

export type CreateWorkOrderRequest = {
  projectUuid: string;
  serviceTypeId: number;
  airtableProjectId?: string;
  clientType?: WorkOrderClientType;
  consultantCompanyId?: number;
  documents?: Document[];
  referralNotes?: string;
  referringCompanyId?: number;
  showPriceToCustomer?: boolean;
};

export type CreateWorkOrderOneOffRequest = {
  consultantCompanyId: number;
  descriptionOfWork: string;
  hourlyRateDescription: string | null;
  priceCents: number | null;
  projectId: number;
  serviceTypeId: number;
  supplementalDocuments?: Document[];
};

export type CreateCityCommentsWorkOrderRequest = {
  airtableProjectId: string;
  consultantCompanyId: number;
  originalWorkOrderUuid: string;
  resourceLink: string;
};

export type UpdateCityCommentsWorkOrderRequest = {
  descriptionOfWork: string;
  priceCents: number;
  workOrderUuid: string;
  supplementalDocuments?: Document[];
  updatedByConsultantId?: number;
};

export type ServiceType = Prisma.service_typesGetPayload<null>;

export type SupportSubCategory = {
  id: number;
  name: string;
  type: CategoryType;
};
export type SupportCategory = SupportSubCategory & {
  subcategories: SupportSubCategory[];
};
// Matches the "name" of a support subcategory to its associated category
export type SupportCategories = Record<string, SupportCategory>;

export const InvoiceInclude = {
  include: {
    customers: {
      select: {
        id: true,
        email: true,
        full_name: true,
      },
    },
    invoice_documents: {
      select: {
        documents: DocumentSelect,
      },
    },
    gc_companies: {
      select: {
        id: true,
        email: true,
        name: true,
        address: true,
        city: true,
        state: true,
        zip_code: true,
      },
    },
    issuer_company: {
      select: {
        id: true,
        company_type: true,
        email: true,
        name: true,
        address: true,
        city: true,
        state: true,
        zip_code: true,
      },
    },
    projects: {
      select: {
        id: true,
        uuid: true,
        name: true,
        zip_code: true,
        project_customers: {
          select: {
            customers: {
              select: {
                full_name: true,
                email: true,
              },
            },
          },
        },
      },
    },
  },
};

export type InvoiceIncludePayload = Prisma.project_invoicesGetPayload<
  typeof InvoiceInclude
>;

export type ComputedMedia = {
  // Used for videos
  embedUrl?: string;
  thumbnailUrl?: string;

  // Used for images
  url?: string;
};

export type Media = Prisma.mediaGetPayload<undefined> & ComputedMedia;

// Use this in each place you query the work_orders table so we have uniform response
// fields across all queries
export const workOrderInclude =
  Prisma.validator<Prisma.work_ordersDefaultArgs>()({
    include: {
      company: {
        select: {
          id: true,
          name: true,
          email: true,
          address: true,
          city: true,
          state: true,
          zip_code: true,
        },
      },
      accepted_by_pro: {
        select: {
          first_name: true,
          last_name: true,
          email: true,
        },
      },
      accepted_by_client_pro: {
        select: {
          first_name: true,
          last_name: true,
          email: true,
          phone_number: true,
        },
      },
      created_by_pro: {
        select: {
          first_name: true,
          last_name: true,
          email: true,
        },
      },
      accepted_by_customer: {
        select: {
          full_name: true,
          email: true,
        },
      },
      project: {
        select: {
          id: true,
          name: true,
          uuid: true,
          google_drive_link: true,
          airtable_id: true,
          platform_service_type: true,
          project_customers: {
            select: {
              status: true,
              customers: {
                select: {
                  full_name: true,
                  email: true,
                },
              },
            },
          },
        },
      },
      service_type: true,
      documents: DocumentSelect,
      referral_documents: DocumentSelect,
      supplemental_documents: DocumentSelect,
      invoices: {
        select: {
          uuid: true,
          status: true,
          total_due_cents: true,
        },
      },
      referral_generated_by: {
        select: {
          full_name: true,
        },
      },
      original_work_order: {
        select: {
          uuid: true,
        },
      },
      extension_work_orders: {
        select: {
          service_type: true,
        },
      },
      referral_generated_by_pro: {
        select: {
          first_name: true,
          last_name: true,
          email: true,
          phone_number: true,
        },
      },
      referral_generated_by_customer: {
        select: {
          full_name: true,
        },
      },
      referring_company: {
        select: {
          name: true,
          profile_picture: true,
          email: true,
        },
      },
      client_pro_company: {
        select: {
          name: true,
          profile_picture: true,
          email: true,
        },
      },
      site_work_scheduled_by_pro: {
        select: {
          first_name: true,
          last_name: true,
        },
      },
    },
  });

export const workOrdersOrderBy: Parameters<
  PrismaClient['work_orders']['findMany']
>[0] = {
  orderBy: [
    {
      completion_due_date: 'asc' as const,
    },
    {
      site_work_scheduled_due_date: 'asc' as const,
    },
    {
      acceptance_due_date_client: 'asc' as const,
    },
    {
      acceptance_due_date_pro: 'asc' as const,
    },
    {
      created_at: 'asc' as const,
    },
  ],
};

export type WorkOrderRoles = {
  isClient: boolean;
  isInternal: boolean;
  isReferrer: boolean;
  isServiceProvider: boolean;
  isViewOnly: boolean;
};

export type WorkOrder = Prisma.work_ordersGetPayload<typeof workOrderInclude>;

export const ChangeOrderInclude =
  Prisma.validator<Prisma.change_ordersDefaultArgs>()({
    include: {
      accepted_by_pro: {
        select: {
          first_name: true,
          last_name: true,
          email: true,
          phone_number: true,
        },
      },
      accepted_by_customer: {
        select: {
          full_name: true,
          email: true,
        },
      },
      contract: {
        select: {
          uuid: true,
          name: true,
        },
      },
      changes_requested_by_customer: {
        select: {
          full_name: true,
          email: true,
        },
      },
      invoice: {
        select: {
          uuid: true,
        },
      },
      project: {
        select: {
          id: true,
          name: true,
          uuid: true,
          airtable_id: true,
          is_multibid: true,
          project_customers: {
            select: {
              customers: {
                select: {
                  full_name: true,
                  email: true,
                },
              },
            },
          },
        },
      },
      company: {
        select: {
          id: true,
          name: true,
          profile_picture: true,
          users: {
            select: {
              first_name: true,
              last_name: true,
              id: true,
              email: true,
              is_owner: true,
            },
          },
        },
      },
    },
  });

export type ChangeOrder = Prisma.change_ordersGetPayload<
  typeof ChangeOrderInclude
>;

export type TermsOfService = Prisma.terms_of_servicesGetPayload<null>;

export type WorkOrderAcceptRequest = {
  uuid: string;
  consultantData?: WorkOrderAcceptConsultantData;
  supplementalDocuments?: Document[];
};

export type WorkOrderSetDueDateRequest = {
  airtableProjectId: string;
  date: Date;
};

export type WorkOrderAcceptConsultantData = {
  descriptionOfWork: string;
  hourlyRateDescription: string | null;
  priceCents: number | null;
  serviceTypeId: number;
  projectPhase?: string | null;
};

export type WorkOrderScheduleSiteWorkRequest = {
  date: Date;
  uuid: string;
  siteWorkNotes?: string | null;
};

export type WorkOrderDeclineRequest = {
  reason: string;
  uuid: string;
};

export type WorkOrderVoidRequest = {
  reason: string;
  uuid: string;
};

export type InvoiceVoidRequest = {
  reason: string;
  uuid: string;
};

export type CompleteDeliverableRequest = {
  deliverableSummary: string | null;
  documents: Document[];
  workOrderUuid: string;
};

export enum ContractType {
  CHANGE_ORDER = 'change order',
  PROPOSAL = 'proposal',
}

export type GenChangeOrderDocusignEnvelopeRequest = {
  changeOrderUuid: string;
  changeOrderVersion: number;
  projectUuid: string;
};

export type DocusignSigner = {
  email: string;
  id: string;
  name: string;
  role: string;
};

export type GenDocusignEnvelopeResponse = {
  message: string;
  envelopeId?: string;
};

export type DocusignESignatureResponse = {
  data: {
    envelopeId: string;
    envelopeSummary: EnvelopeDefinition;
    recipientId: string;
  };
};

export type ProjectChangeOrdersRequest = {
  changeOrderQueryType: ChangeOrderQueryType;
  uuid: string;
};

export enum ChangeOrderQueryType {
  ALL_CHANGE_ORDERS = 'ALL_CHANGE_ORDERS',
  CONTRACT_SIGNED_CHANGE_ORDERS = 'CONTRACT_SIGNED_CHANGE_ORDERS',
  LATEST_CHANGE_ORDERS = 'LATEST_CHANGE_ORDERS',
}

export type BidsRequest = {
  projectUuid: string;
};

export type GcOwner = {
  email: string;
  name: string;
};

export type CustomerFeedbackResponse = {
  feedback: HighlightTag[];
};

export type ProcurementCategoryDetails = {
  quoteRequestUrl: string;
  catalogUrl?: string;
};

export type ProcurementCategoryDetailsMap = {
  [key in ProcurementCategory]: ProcurementCategoryDetails;
};

export type GetProcurementDetailsRequest = {
  projectAirtableId?: string;
};

export type GetProcurementDetailsResponse = {
  quoteRequestsUrl: string;
  quotesUrl: string;
  ordersUrl?: string;
  procurementCategoryDetailsMap?: ProcurementCategoryDetailsMap;
  projectsUrl?: string;
};

export type CreateSupportTicketRequest = {
  submitterEmail: string;
  submitterType: string;
  supportCategory: string;
  supportDescription: string;
  customerFirstName?: string;
  customerLastName?: string;
  projectRecordId?: string;
  projectUuid?: string;
  submitterCompany?: string;
  supportSubCategory?: string;
  ticketAttachments?: DownloadDocuments[];
};

export type RequestProTicketRequest = {
  description: string;
  projectAddress: string;
  requestCategory: string;
  submitterEmail: string;
  submitterCompany?: string;
};

export type CustomerSubmitFeedbackRequest = {
  constructionQuality: number;
  constructionSpeed: number;
  cottageGeneralFeedback: string;
  cottageRecommendation: number;
  gcCommunication: number;
  gcCompanyId: number;
  gcGeneralFeedback: string;
  gcValue: number;
  hasDeclinedToSendToGc: boolean;
  projectId: number;
  projectPhotos: Document[];
  submitterEmail: string;
  submitterName: string;
};

export type GcSubmitFeedbackRequest = {
  cottageSupportTeam: number;
  designQuality: number;
  generalFeedback: string;
  invoicingAndPayments: number;
  overallExperience: number;
  projectId: number;
  projectManagement: number;
  projectPhotos: Document[];
  submitterEmail: string;
  consultantsQuality?: number;
  submitterCompany?: string;
};

export type CreateChangeOrderRequest = {
  changeOrderDetails: string;
  constructionCost: number;
  document: Document;
  gcCompanyId: number;
  projectId: number;
  totalPriceCents: number;
};

export type CreateChangeOrderFromUpdateRequest = {
  constructionCost: number;
  gcCompanyId: number;
  projectId: number;
  uuid: string;
  // This is the new version of the change order that will be created
  version: number;
};

export type ChangeOrderVoidRequest = {
  reason: string;
  uuid: string;
  version: number;
};

export type ChangeOrderChangeRequestGcRequest = {
  changesRequestedByGcId: number;
  constructionCost: number;
  uuid: string;
  version: number;
};

export type ChangeOrderChangeRequestCustomerRequest = {
  changeOrderUuid: string;
  notes: string;
  projectUuid: string;
  version: number;
};

export type ChangeOrderCustomerAcceptRequest = {
  changeOrderUuid: string;
  projectUuid: string;
  version: number;
};

export type ChangeOrderHistoryResponse = {
  firstChangeOrder: ChangeOrder | null;
  latestChangeOrderHigherThanV1: ChangeOrder | null;
  latestChangeRequestedByCustomerChangeOrder: ChangeOrder | null;
  latestChangeRequestedByGcChangeOrder: ChangeOrder | null;
};

export type ChangeOrderSentToCustomerRequest = {
  changeOrderDetails: string;
  changeOrderUuid: string;
  changeOrderVersion: number;
  constructionCost: number;
  document: Document;
  gcCompanyId: number;
  projectId: number;
  totalPriceCents: number;
};

export type AgreementService = Prisma.agreement_servicesGetPayload<
  typeof AgreementServiceInclude
>;

export const AgreementServiceInclude = {
  include: {
    invoice: {
      select: {
        status: true,
        uuid: true,
      },
    },
  },
};

export const AgreementInclude = {
  include: {
    projects: {
      select: {
        id: true,
        name: true,
        uuid: true,
        airtable_id: true,
        project_customers: {
          select: {
            customers: {
              select: {
                full_name: true,
                email: true,
              },
            },
          },
        },
      },
    },
    agreement_services: AgreementServiceInclude,
    terms_of_services: true,
    accepted_by_customer: {
      select: {
        full_name: true,
        email: true,
      },
    },
    voided_by_internal: {
      select: {
        full_name: true,
        email: true,
      },
    },
  },
};

export type Agreement = Prisma.agreementsGetPayload<typeof AgreementInclude>;

export type AgreementServiceInfo = {
  paymentAmountCents: number;
  serviceType: AgreementServiceType;
};

export type CreateAgreementRequest = {
  agreementType: AgreementType;
  airtableProjectRecordId: string;
  projectAddendumPdfUrl: string;
  projectProposalPdfUrl: string;
  serviceInfos: AgreementServiceInfo[];
};

export type CustomerAgreementsResponse = {
  agreements: Agreement[];
};

export type AgreementAcceptRequest = {
  uuid: string;
};

export type AgreementAcceptResponse = {
  updatedAgreement: Agreement;
};

export type AgreementViewRequest = {
  agreementUuid: string;
};

export type AgreementVoidRequest = {
  reason: string;
  uuid: string;
};

export type ProposalViewRequest = {
  proposalUuid: string;
  proposalVersion: string;
};

export type PhaseAndStageInfo = {
  isCurrent: boolean;
  phaseName: string;
  stageName: string;
};

export type CreateProjectRequest = {
  city: string;
  platformServiceType: PlatformServiceType;
  region: string;
  streetAddress: string;
  zipcode: string;
  // Not filled out when from the marketing site, only filled out
  // from projects created via the sales flow
  airtableRecordId?: string;
};

export type UpdateProjectRequest = {
  airtableRecordId: string;
};

export type CreateProjectResponse = {
  projectUuid: string;
};

export type CreateUserRequest = {
  role: string;
  userEmail: string;
  companyId?: number;
  firstName?: string;
  isOwner?: boolean;
  lastName?: string;
  projectUuid?: string;
};

export type UpdateUserRequest = {
  uuid: string;
  email?: string;
  firstName?: string;
  isOwner?: boolean;
  lastName?: string;
  phoneNumber?: string;
  status?: UserStatus;
};

export enum UserSignUpFlow {
  SALES_EMAIL = 'email',
}

type UserSignUpRequestBaseParams = {
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  role: string;
  userEmail: string;
};

type UserSignUpRequestCustomerParams = UserSignUpRequestBaseParams & {
  projectUuid: string;
  isConfirmedUponCompletion?: boolean;
  isOwner?: boolean;
  validationToken?: never;
};

type UserSignUpRequestDefaultParams = UserSignUpRequestBaseParams & {
  validationToken: string;
  isConfirmedUponCompletion?: never;
  isOwner?: never;
  projectUuid?: never;
};

export type UserSignUpRequest =
  | UserSignUpRequestDefaultParams
  | UserSignUpRequestCustomerParams;

export type GetAuthTokenRequest = {
  impersonatorEmail: string;
  role: string;
  targetUserId: number;
};

export type ResendInviteRequest = {
  role: string;
  userEmail: string;
  projectUuid?: string;
};

export type UpdateEmailRequest = {
  newUserEmail: string;
  role: string;
  userId: number;
};

export type CreateConstructionKickoffRequest = {
  airtableRecordId: string;
  gcCompanyId: number;
  permittingNotes: string;
};

export type CreateConstructionKickoffResponse = {
  uuid: string;
};

export const ConstructionKickoffInclude = {
  include: {
    company: {
      select: {
        id: true,
        name: true,
        users: {
          select: {
            first_name: true,
            last_name: true,
            email: true,
            is_owner: true,
          },
        },
      },
    },
    site_visit_scheduled_by_pro: {
      select: {
        first_name: true,
        last_name: true,
        email: true,
      },
    },
    construction_start_date_scheduled_by_pro: {
      select: {
        first_name: true,
        last_name: true,
        email: true,
      },
    },
    completed_by_pro: {
      select: {
        first_name: true,
        last_name: true,
        email: true,
      },
    },
    project: {
      select: {
        id: true,
        name: true,
        uuid: true,
        airtable_id: true,
        is_multibid: true,
        project_customers: {
          select: {
            status: true,
            customers: {
              select: {
                full_name: true,
                email: true,
                phone_number: true,
              },
            },
          },
        },
      },
    },
  },
};

export type ConstructionKickoff = Prisma.construction_kickoffsGetPayload<
  typeof ConstructionKickoffInclude
>;

export type CreateCompanyRequest = {
  companyEmail: string;
  companyName: string;
  role: string;
  aboutInfo?: string;
  address?: string;
  city?: string;
  companyCache?: CompanyCache;
  consultantTypes?: string[];
  marketplaceFeePercentage?: number;
  projectsCompletedOffPlatform?: number;
  regions?: string[];
  state?: string;
  staticAttributes?: StaticAttribute[];
  superproBadge?: CompanyMedal;
  testimonials?: Testimonial[];
  website?: string;
  zipCode?: string;
};

export type UpdateCompanyRequest = {
  companyId: number;
  aboutInfo?: string;
  address?: string;
  badges?: HighlightTag[];
  city?: string;
  companyEmail?: string;
  companyName?: string;
  consultantTypes?: ConsultantType[];
  experiencedMunicipalities?: string[];
  isActive?: boolean;
  marketplaceFeePercentage?: number;
  medal?: CompanyMedal;
  profilePicture?: string;
  projectsCompletedOffPlatform?: number;
  regions?: Region[];
  state?: string;
  staticAttributes?: StaticAttribute[];
  website?: string;
  zipCode?: string;
};

export type CompanyCache = {
  projectsCompleted: number;
  experiencedMunicipalities?: string[];
  highlightTags?: string[];
};

export type CreateCompanyResponse = {
  companyId: number;
};

export type AssignProjectRequest = {
  companyId: number;
  projectId: number;
  role: string;
};

export type UpdateConstructionKickoffRequest = {
  constructionStartDate: Date | null;
  kickoffNotes: string | null;
  status: ConstructionKickoffStatus;
};

export type ConstructionKickoffScheduleSiteVisitRequest = {
  siteVisitDate: Date;
};

export type ConstructionCloseoutEmailRequest = {
  airtableRecordId: string;
};

export type UpcomingConstructionCloseoutEmailRequest = {
  airtableRecordId: string;
  substantialCompletionDate: Date;
};

export type CreateUpsellWorkOrderReferralsRequest = {
  airtableProjectId: string;
  consultantType: ConsultantType;
  serviceTypeId: number;
};

export type StripeCreditCardInputFormResponse = {
  clientSecret: string; // https://docs.stripe.com/api/customer_sessions/object#customer_session_object-client_secret
};

export const PurchaseOrderInclude =
  Prisma.validator<Prisma.purchase_orderDefaultArgs>()({
    include: {
      project: {
        select: {
          id: true,
          name: true,
          uuid: true,
          airtable_id: true,
        },
      },
      invoices: {
        select: {
          status: true,
          uuid: true,
          issued_at: true,
          paid_at: true,
          total_due_cents: true,
        },
      },
      documents: DocumentSelect,
      supplier_company: {
        select: {
          id: true,
          name: true,
          email: true,
        },
      },
      purchaser_company: {
        select: {
          id: true,
          name: true,
          email: true,
          profile_picture: true,
        },
      },
      confirmed_by_supplier: {
        select: {
          id: true,
          first_name: true,
          last_name: true,
          email: true,
          phone_number: true,
        },
      },
      placed_by_pro: {
        select: {
          id: true,
          first_name: true,
          last_name: true,
          email: true,
          phone_number: true,
        },
      },
    },
  });

type ComputedPurchaseOrder = {
  paid_at?: Date;
  remaining_total_cents?: number;
};

export type PurchaseOrder = Prisma.purchase_orderGetPayload<
  typeof PurchaseOrderInclude
> &
  ComputedPurchaseOrder;

export type ConfirmPurchaseOrderRequest = {
  documents: Document[];
  estimatedLeadTime: string;
  grandTotalCents: number;
  materialsTotalCents: number;
  uuid: string;
};

export type CreatePurchaseOrderRequest = {
  airtableProjectId: string;
  airtableQuoteRecordId: string;
  category: PrismaProcurementCategory;
  noteFromGc: string;
  purchaserCompanyId: number;
  supplierCompanyId: number;
  datePlaced?: Date;
};

export type PaymentMethod = {
  paymentMethodType: string;
  bankName?: string;
  brand?: string;
  createdAt?: Date;
  expMonth?: number;
  expYear?: number;
  last4?: string;
  paymentMethodId?: string;
};

export type ListPaymentMethodsResponse = {
  defaultPaymentMethodId: string | null;
  paymentMethods: PaymentMethod[];
};

export type ListPaymentMethodsRequest = {
  invoiceUuid: string;
};

export type GetInvoiceResponse = {
  invoice: Invoice;
  invoiceRole: InvoiceRole;
};

export type GetWorkOrderResponse = {
  workOrder: WorkOrder;
  workOrderRoles: WorkOrderRoles;
};

export type MigrateCompanyResponse = BasePgCompany;

export type RequestProRequest = {
  description: string;
  projectAddress: string;
  requestedRole: Role;
  requestedConsultantType?: ConsultantType;
};

export type GetBuildIdResponse = {
  buildId?: string;
};

export type UpsertTestimonialRequest = {
  companyId: number;
  reviewerLocation: string;
  reviewerName: string;
  text: string;
  uuid?: string;
};

export type MarkInvoiceAsPaid = {
  invoiceUuid: string;
  paymentMethod: PaymentMethodType;
  reason: string;
};
