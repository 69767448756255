import { Group, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';

import { Testimonial } from '@/types/api';

import { CottageTooltip } from '../shared/default';

type CompanyTestimonialsProps = {
  companyTestimonials: Testimonial[];
  altTestimonialsCount?: number;
  showOne?: boolean;
};

export const CompanyTestimonials = ({
  companyTestimonials,
  altTestimonialsCount,
  showOne,
}: CompanyTestimonialsProps) => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const companyTestimonialsLength = altTestimonialsCount
    ? altTestimonialsCount
    : companyTestimonials.length;

  const handlePrevious = () => {
    setTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? companyTestimonialsLength - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setTestimonialIndex((prevIndex) =>
      prevIndex === companyTestimonialsLength - 1 ? 0 : prevIndex + 1
    );
  };

  const currentTestimonial =
    companyTestimonials[showOne ? 0 : testimonialIndex];

  return (
    <Stack bg="blue.0" p="md" style={{ borderRadius: '4px' }}>
      <Text>{currentTestimonial.text}</Text>
      <Stack spacing="xxxxs">
        <Text fw="bold" c="blue.9" fz="sm">
          {currentTestimonial.reviewer_name}
        </Text>
        <Group position="apart" align="center">
          <Text c="blue.6" fz="sm">
            {currentTestimonial.reviewer_location}
          </Text>
          {companyTestimonialsLength > 1 && (
            <>
              {showOne ? (
                <CottageTooltip label="See full profile after designer accepts invite">
                  <Text
                    c="blue.6"
                    fz="sm"
                    style={{
                      cursor: 'default',
                    }}
                  >
                    {`+ ${companyTestimonialsLength - 1} more`}
                  </Text>
                </CottageTooltip>
              ) : (
                <Group spacing="xxxxs">
                  <TbChevronLeft
                    size={24}
                    onClick={handlePrevious}
                    cursor="pointer"
                  />
                  <TbChevronRight
                    size={24}
                    onClick={handleNext}
                    cursor="pointer"
                  />
                </Group>
              )}
            </>
          )}
        </Group>
      </Stack>
    </Stack>
  );
};
