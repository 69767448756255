import * as Sentry from '@sentry/nextjs';

import { deserializeDates } from '@/lib/apiUtils';
import { HttpError } from '@/types/api';

/**
 * Creates a fetcher function used for GET calls made using SWR with a JSON
 * response body
 */
export const createGetFetcher = (entityName?: string) => {
  return async <T>(url: string): Promise<T> => {
    try {
      const response = await fetch(url);
      const data = await response.json();

      // Don't surface error message to user if 500 error
      if (response.status === 500) {
        throw new Error(
          'Something went wrong. Please contact support if issue persists.'
        );
      }

      if (!response.ok) {
        throw new Error((data as HttpError).message);
      }

      return deserializeDates(data);
    } catch (error) {
      const name = entityName ?? 'data';
      const errorMessage =
        error instanceof Error
          ? `Failed to fetch ${name} with error ${error.message}`
          : `Failed to fetch ${name}`;
      throw new Error(errorMessage);
    }
  };
};

type MethodWithBody = 'DELETE' | 'PATCH' | 'POST' | 'PUT';

type CreateFetcherWithBodyParams = {
  method: MethodWithBody;
  errorMessage?: string;
};

/**
 * Creates a fetcher function used for SWR calls with a JSON request body
 * and JSON response body
 */
export const createFetcherWithBody = ({
  method,
  errorMessage,
}: CreateFetcherWithBodyParams) => {
  return async <T>(url: string, { arg }: { arg: T }) => {
    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(arg),
      });
      const data = await response.json();

      // Don't surface error message to user if 500 error
      if (response.status === 500) {
        throw new Error(
          'Something went wrong. Please contact support if issue persists.'
        );
      }

      if (!response.ok) {
        throw new Error((data as HttpError).message);
      }

      return deserializeDates(data);
    } catch (error) {
      Sentry.addBreadcrumb({
        level: 'error',
        data: {
          error,
        },
      });
      throw errorMessage
        ? new Error(`${errorMessage}: ${(error as Error).message}`)
        : new Error((error as Error).message);
    }
  };
};
